<template>
    <div v-if="word" class="price">
        <div class="price-title">
            <div>{{word.price.page_title[language]}}</div>
            <img src="./../assets/search.png" alt="">
        </div>
        <div class="price-list">
            <div class="search-input">
                <input @input="searchCountry" @focus="showCountryList = true;keyword = ''" @blur="hiddenSelect" :placeholder="word.price.keyword[language]" type="text" v-model="keyword">
                <div v-show="showCountryList" class="country-list">
                    <div @click="choose(item)" v-show="item.show" v-for="item in country" :key="item.countryCodeNumber">
                        <div>({{item.countryCodeNumber}}){{item.name[language]}}</div></div>
                </div>
            </div>
            <div v-if="chooseCountry">
                <div class="price-item-title">
                    <div>{{word.price.title[language]}}({{chooseCountry.name[language]}})</div>
                    <div class="subtitle">{{word.price.subtitle[language]}}</div>
                </div>
                <div v-if="chooseCountry" class="price-item-list">
                    <div></div>
                    <div>
                        <div class="price-item">{{word.price.cny[language]}}</div>
                    </div>
                    <div>
                        <div class="price-item">{{word.price.eur[language]}}</div>
                    </div>
                    <div>
                        <div class="price-item">{{word.price.usd[language]}}</div>
                    </div>
                </div>
                <div class="price-item-list">
                    <div>
                        <div class="price-item">{{word.price.opt_price[language]}}</div>
                        <div class="price-item">{{word.price.notifications_price[language]}}</div>
                    </div>
                    <div>
                        <div class="price-item">Rp.<span>{{(chooseCountry.euValidaPrice*15826).toFixed(4)}}</span>/{{word.price.unit[language]}}</div>
                        <div class="price-item">Rp.<span>{{(chooseCountry.euMarketPrice*15826).toFixed(4)}}</span>/{{word.price.unit[language]}}</div>
                    </div>
                    <div>
                        <div class="price-item">€<span>{{chooseCountry.euValidaPrice}}</span>/{{word.price.unit[language]}}</div>
                        <div class="price-item">€<span>{{chooseCountry.euMarketPrice}}</span>/{{word.price.unit[language]}}</div>
                    </div>
                    <div>
                        <div class="price-item">$<span>{{chooseCountry.usValidaPrice}}</span>/{{word.price.unit[language]}}</div>
                        <div class="price-item">$<span>{{chooseCountry.usMarketPrice}}</span>/{{word.price.unit[language]}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/utils/request/request.js'
import base from '@/utils/request/base.js'
import word from './../assets/json/language.json'
import country from './../assets/json/country.json'
import 'animate.css';
export default {
    name: 'Price',
    components: {},
    data() {
        return {
            language: '',
            word: word,
            showCountryList: false,
            country: country,
            keyword: '',
            chooseCountry: null
        }
    },
    created() {
        this.language = localStorage.getItem('language')
        for(let item of this.country) {
            item.show = true
        }
        this.keyword = '(' + this.country[0].countryCodeNumber + ')' + this.country[0].name[this.language];
        this.chooseCountry = this.country[0];
    },
    methods: {
        hiddenSelect() {
            setTimeout(()=>{
                this.showCountryList = false;
            }, 200)
        },
        choose(e) {
            this.keyword = '(' + e.countryCodeNumber + ')' + e.name[this.language];
            this.chooseCountry = e;
            for(let item of this.country) {
                item.show = true;
            }
        },
        searchCountry() {
            for(let item of this.country) {
                item.show = false;
                if(item.name[this.language].indexOf(this.keyword) > -1 || item.countryCodeNumber.indexOf(this.keyword) > -1) {
                    item.show = true;
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .price-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 70%;
        margin: 50px auto;
        font-size: 48px;
        font-weight: 800;
        img {
            width: 40%;
        }
    }
    .search-input {
        height: 50px;
        padding: 10px 0;
        width: 35%;
        min-width: 400px;
        margin-bottom: 50px;
        position: relative;
        .country-list {
            position: absolute;
            top: 55px;
            left: 16px;
            max-height: 200px;
            padding: 0 0 2px;
            width: 100%;
            background-color: #fff;
            overflow: auto;
            box-shadow: 0px 5px 5px rgba(0,0,0,.2);
            div {
                cursor: pointer;
                padding: 5px;
                &:hover {
                    background-color: #E2EFF7;
                }
            }
        }
        input {
            border-radius: 8px;
            border: 1px solid #e2e2e2;
            height: 100%;
            width: 100%;
            padding: 0 10px;
            font-size: 18px;
            &:focus {
                outline:none;
                border: 1px solid #e2e2e2;
            }
        }
    }
    .price-list {
        margin-bottom: 100px;
        background-color: #FBFBFB;
        padding: 40px 60px 80px;
        width: calc(70% - 80px);
        margin-left: 15%;
        >div {
            padding: 0 16px;
            .price-item-title {
                font-size: 40px;
                font-weight: bold;
                margin-bottom: 60px;
                .subtitle {
                    font-size: 14px;
                    font-weight: normal;
                    margin-top: 10px;
                    color: #999;
                }
            }
            .price-item-list {
                width: 100%;
                display: flex;
                align-items: flex-end;
                >div {
                    width: 25%;
                    flex-shrink: 0;
                    .price-item {
                        margin-top: 20px;
                        span {
                            color: #108CCF;
                            margin: 0 4px;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
</style>
